.dataCircle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0 0 10px #00aeef;
}

.innerDataCircle {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 6px solid #00aeef;
}

.dataCircle:hover .innerDataCircle {
  background-color: aqua;
  transition: ease-in-out 0.5s;

}
