@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

.App {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  background-color: #f3f5f7;
}

ul,
li {
  list-style-type: none;
}
