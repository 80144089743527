.attributeCard {
  background-color: rgba(11, 84, 139, 0.157);
  color: #062f4e;
  border-radius: 20px;
  box-shadow: 0px 0px 5px #0b548bbb;
  transition: ease-in-out 0.5s;
}

.attributeCard:hover {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
}
