.navDiv {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.menuItem h5 {
  color: #009edb;
}

.menuItem:hover h5 {
  background-color: #009edb;
  color: #ffffff;
  transition: ease-in-out 0.5s;
}
