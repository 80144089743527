@media (min-width: 992px) and (max-width: 1000px) {
  .heading1 {
    display: block;
  }
  .heading2 {
    display: none;
  }
}

@media (min-width: 100px) and (max-width: 991px) {
  .article0 {
    display: none;
  }
  .article1 {
    display: none;
  }
  .article2 {
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1310px) {
  .article0 {
    display: block;
  }
  .article1 {
    display: none;
  }
  .article2 {
    display: none;
  }
}

@media (min-width: 1311px) and (max-width: 1649px) {
  .article0 {
    display: none;
  }
  .article1 {
    display: block;
  }
  .article2 {
    display: none;
  }
}

@media (min-width: 1650px) {
  .article0 {
    display: none;
  }
  .article1 {
    display: none;
  }
  .article2 {
    display: block;
  }
}
